import React from "react";
import thePayTracker from "../assets/thepaytracker.svg";
export default function Brands() {
  const brands = [
    {
      name: "The Pay Tracker",
      logo: thePayTracker,
      link: "http://thepaytracker.com",
    },
    // { name: "Mattered", logo: "/placeholder.svg" },
    // { name: "Coca-Cola", logo: "/placeholder.svg" },
    // { name: "Adobe", logo: "/placeholder.svg" },
    // { name: "Subway", logo: "/placeholder.svg" },
    // { name: "Codecademy", logo: "/placeholder.svg" },
  ];

  return (
    <div className="py-16 px-4">
      <div className="max-w-6xl mx-auto grid grid-cols-2 md:grid-cols-3 lg:grid-cols-1 gap-8 items-center">
        {brands.map((brand) => (
          <div key={brand.name} className="flex justify-center">
            <a href={brand.link} target="_blank" rel="noopener noreferrer">
              {" "}
              {/* Added anchor tag */}
              <img
                src={brand.logo}
                alt={brand.name}
                className="h-8 object-contain opacity-60 hover:opacity-100 transition-opacity"
              />
            </a>
          </div>
        ))}
      </div>
    </div>
  );
}
