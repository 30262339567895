import React from "react";
import profile_pic from "../assets/profile.jpg";
export default function Hero() {
  return (
    <section
      className="text-center py-20 bg-neutral-100"
      style={{
        borderRadius: "0px 0px 40px 40px",
        border: "2px solid white",
        borderColor: "transparent #fff transparent #fff",
      }}
    >
      <div className="relative inline-block mb-8">
        <img
          src={profile_pic}
          alt="Profile"
          className="w-28 h-28 rounded-full border-white border-2"
        />
        <div
          className="absolute bg-white px-3 py-1 rounded-full shadow-sm origin-bottom -rotate-12"
          style={{ top: "8px", left: "76px", width: "130px" }}
        >
          <span className="text-sm">
            {" "}
            Srinu Dola{" "}
            <span role="img" className="mx-1" aria-label="waving hand">
              👋
            </span>
          </span>
        </div>
      </div>
      <h1 className="text-4xl md:text-5xl font-medium max-w-2xl mx-auto leading-tight mb-8">
        Building <span className="text-gray-600">digital</span> products,{" "}
        <span className="text-gray-600">brands</span>, and{" "}
        <span className="text-gray-600">experience</span>.
      </h1>
      <button className="bg-black text-white px-6 py-3 rounded-full inline-flex items-center space-x-2 hover:bg-gray-800 transition-colors">
        <span>Latest shots</span>
        <span className="ml-2">→</span>
      </button>
    </section>
  );
}
