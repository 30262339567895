import "./App.css";
import Header from "./components/header.tsx";
import Hero from "./components/hero.tsx";
import Brands from "./components/brands.tsx";
import Services from "./components/services.tsx";
import Contact from "./components/contact.tsx";
import Footer from "./components/footer.tsx";

function App() {
  return (
    <div className="min-h-screen bg-[#F5F5F5]">
      <div className="max-w-7xl mx-auto bg-[#FAFAFA] rounded-[40px] shadow-xl my-8 overflow-hidden">
        <Header />
        <main>
          <Hero />
          <Brands />
          <Services />
          {/* <Contact /> */}
        </main>
        <Footer />
      </div>
    </div>
  );
}

export default App;
