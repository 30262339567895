import React, { useState } from "react";
import ResumePDF from "../assets/SrinuDola_resume_2024.pdf";

export default function Header() {
  const [isPopoverOpen, setPopoverOpen] = useState(false);

  const handleDownload = () => {
    // Download CV action
    window.open(ResumePDF); // Replace with the actual path to your CV file
  };

  return (
    <header
      className="flex justify-between items-center px-8 py-6 bg-neutral-100 border-b border-gray-100"
      style={{
        borderRadius: "40px 40px 0px 0px",
        border: "2px solid white",
        borderColor: "#fff #fff transparent #fff",
      }}
    >
      <div>
        <a
          href="mailto:sree.it2020@gmail.com"
          className="text-sm text-gray-600 hover:text-gray-900"
        >
          sree.it2020@gmail.com
        </a>
        <button
          onClick={() => setPopoverOpen(true)}
          className="rounded-full mx-4 px-6 py-2 bg-white text-sm text-gray-600 hover:text-gray-900"
        >
          CV
        </button>
      </div>
      <nav className="flex space-x-4">
        <div className="flex items-center space-x-3 ml-4">
          <a
            href="https://www.youtube.com/@srinu_ui2939"
            target="_blank"
            rel="noopener noreferrer"
            className="text-sm text-gray-600 hover:text-gray-900"
          >
            YouTube
          </a>
          <span className="text-gray-300">/</span>
          <a
            href="https://www.linkedin.com/in/srinudola/"
            target="_blank"
            rel="noopener noreferrer"
            className="text-sm text-gray-600 hover:text-gray-900"
          >
            LinkedIn
          </a>
          <span className="text-gray-300">/</span>
          <a
            href="https://www.instagram.com/srinu_dola_/"
            target="_blank"
            rel="noopener noreferrer"
            className="text-sm text-gray-600 hover:text-gray-900"
          >
            Instagram
          </a>
        </div>
      </nav>

      {/* Popover */}
      {isPopoverOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div
            className="bg-white p-6 rounded-lg shadow-lg w-4/5 max-w-4xl"
            style={{ height: "80vh" }}
          >
            <h3 className="text-lg font-medium mb-4">Curriculum Vitae</h3>
            <div className="border rounded-md overflow-auto p-4 mb-4 h-5/6">
              <iframe
                src={ResumePDF} // Replace with the actual path to your CV file
                className="w-full h-full"
                title="CV Preview"
              ></iframe>
            </div>
            <div className="flex justify-end space-x-4">
              <button
                onClick={() => setPopoverOpen(false)}
                className="px-4 py-2 text-gray-800 rounded"
              >
                Cancel
              </button>
              <button
                onClick={handleDownload}
                className="bg-black text-white px-6 py-2 rounded-full inline-flex items-center space-x-2 hover:bg-gray-800 transition-colors"
              >
                Download
              </button>
            </div>
          </div>
        </div>
      )}
    </header>
  );
}
