import React from "react";

export default function Footer() {
  return (
    <footer className="flex justify-between items-center p-6">
      <div>
        <p className="text-sm text-gray-600">© 2024 All rights reserved.</p>
      </div>
      <div className="flex items-center space-x-3 ml-4">
        <a
          href="https://www.youtube.com/@SrinuDola"
          target="blank"
          className="text-sm text-gray-600 hover:text-gray-900"
        >
          Youtube
        </a>
        <span className="text-gray-300">/</span>
        <a
          href="https://www.linkedin.com/in/srinudola/"
          target="blank"
          className="text-sm text-gray-600 hover:text-gray-900"
        >
          LinkedIn
        </a>

        <span className="text-gray-300">/</span>
        <a
          href="https://www.instagram.com/srinu_dola_/"
          target="blank"
          className="text-sm text-gray-600 hover:text-gray-900"
        >
          Instagram
        </a>
      </div>
    </footer>
  );
}
