import { MonitorSmartphone, Smartphone, PenTool, Code } from "lucide-react";
import React from "react";

export default function Services() {
  const services = [
    {
      icon: <MonitorSmartphone className="w-6 h-6" />,
      title: "UX & UI",
      description:
        "Designing interfaces that are intuitive, efficient, and enjoyable to use.",
    },
    {
      icon: <Smartphone className="w-6 h-6" />,
      title: "Web & Mobile App",
      description:
        "Transforming ideas into exceptional web and mobile app experiences.",
    },
    // {
    //   icon: <PenTool className="w-6 h-6" />,
    //   title: "Design & Creative",
    //   description:
    //     "Crafting visually stunning designs that connect with your audience.",
    // },
    {
      icon: <Code className="w-6 h-6" />,
      title: "Development",
      description:
        "Bringing your vision to life with the latest technology and design trends.",
    },
  ];

  return (
    <section className="py-10 px-4">
      {/* <div className="text-center max-w-2xl mx-auto mb-16">
        <h2 className="text-3xl md:text-4xl font-medium mb-4">
          Collaborate with{" "}
          <span className="text-gray-600">brands and agencies</span>
        </h2>
        <p className="text-xl text-gray-600">to create impactful results.</p>
        <div className="mt-4 inline-block bg-white px-4 py-2 rounded-full shadow-sm">
          <span className="text-sm">Services</span>
        </div>
      </div> */}

      <div className="max-w-6xl mx-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        {services.map((service) => (
          <div key={service.title} className="p-6">
            <div className="mb-4">{service.icon}</div>
            <h3 className="text-lg font-medium mb-2">{service.title}</h3>
            <p className="text-gray-600 text-sm">{service.description}</p>
          </div>
        ))}
      </div>
    </section>
  );
}
